import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #222;
  width: 100vw;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 800px; /* Match canvas width */
  height: 400px; /* Match canvas height */
  overflow: hidden; /* Hide overflow */
  box-shadow: ${({ blur }) => `inset 0 0 ${blur}px white`}; /* Add dynamic inner shadow */
  border-radius: 30px;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  background-color: ${({ color }) => color};
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;

const Knob = styled.input`
  appearance: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  outline: none;
  margin: 0 10px;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #03E23C;
    cursor: pointer;
  }
`;

const EntropyAnimation = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [speed, setSpeed] = useState(2);
    const canvasRef = useRef(null);
    const animationRef = useRef(null);
    const dotsRef = useRef([]);
    const lastDotCreationTimeRef = useRef(Date.now());

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 800;
        canvas.height = 600;

        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            dotsRef.current.forEach((dot, index) => {
                ctx.beginPath();
                ctx.arc(dot.x, dot.y, 10, 0, 2 * Math.PI);
                ctx.fillStyle = index === dotsRef.current.length - 1
                    ? isPlaying
                        ? 'red'
                        : '#03E23C'
                    : 'rgba(255, 255, 255, 0.5)';
                ctx.fill();

                if (index > 0) {
                    const prevDot = dotsRef.current[index - 1];
                    ctx.beginPath();
                    ctx.moveTo(prevDot.x, prevDot.y);
                    ctx.lineTo(dot.x, dot.y);
                    ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                    ctx.stroke();
                }
            });

            const newDot = { x: Math.random() * canvas.width, y: Math.random() * canvas.height };
            const currentTime = Date.now();
            if (dotsRef.current.length < speed && currentTime - lastDotCreationTimeRef.current > 1000 / speed) {
                dotsRef.current.push(newDot);
                lastDotCreationTimeRef.current = currentTime;
            } else if (dotsRef.current.length >= speed) {
                dotsRef.current.shift();
            }

            if (isPlaying) {
                animationRef.current = requestAnimationFrame(animate);
            }
        };

        animationRef.current = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(animationRef.current);
        };
    }, [isPlaying, speed]);

    const toggleAnimation = () => {
        setIsPlaying(!isPlaying);
    };

    const resetAnimation = () => {
        dotsRef.current = [];
        setIsPlaying(false);
        setSpeed(2)
    };

    const handleSpeedChange = (e) => {
        const newSpeed = parseInt(e.target.value);
        setSpeed(newSpeed);
    };

    const blur = speed * 2; // Adjust the blur based on the speed
    const dotCount = speed; // Set the dot count equal to speed

    return (
        <Container>
            <AnimationContainer blur={blur}>
                <Canvas ref={canvasRef} />
            </AnimationContainer>
            <ButtonContainer>
                <Button onClick={toggleAnimation}>
                    {isPlaying ? '⏸️' : '▶️'}
                </Button>
                <Button onClick={resetAnimation}>
                    🔁
                </Button>

                {/* <input type="range" min="2" max="500" value={speed} onChange={handleSpeedChange} /> */}
                <Knob type="range" min="2" max="500" value={speed} onChange={handleSpeedChange} />

            </ButtonContainer>
        </Container>
    );
};

export default EntropyAnimation;
