// store/dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const ShowSubSlice = createSlice({
    name: 'showsub',
    initialState: false,
    reducers: {
        setShowSub: (state, action) => {
            // Merge the existing state with the new data
            return action.payload
        },
    },
});

export const { setShowSub } = ShowSubSlice.actions;
export default ShowSubSlice.reducer;
