import './App.css';
import { Provider } from 'react-redux';
import store from './component/redux/store';
// import MovingSineWaveAnimation from './component/anim';
// import { LogoIcon, SmallLogoIcon } from './component/icons';
import { useState } from 'react';
// import PulsingCircle from './component/animation/pulse';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Slider from './component/slides/slider';
import PasswordPrompt from './component/passwordprompt';
import MainForm from './component/mainform';

const AppWrapper = () => {
    // const [isMobile, setIsMobile] = useState(false);
    // const showsub = useSelector(state => state.showsub)

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    //     };
    //     handleResize(); // Call the function initially
    //     window.addEventListener('resize', handleResize); // Add event listener for window resize
    //     return () => {
    //         window.removeEventListener('resize', handleResize); // Cleanup on unmount
    //     };
    // }, []);

    // const handleButtonClick = () => {
    //     window.open("https://innaffin.win", "_self");
    // };

    return (
        <Router>
            <div className="container">
                <Provider store={store}>
                    <AppRoutes />
                </Provider>
            </div>
        </Router>
    );
};

const AppRoutes = () => {
    const [passwordAccepted, setPasswordAccepted] = useState(false);
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const handlePasswordAccepted = () => {
        setPasswordAccepted(true);
    };

    return (
        <>
            {isHomePage && (
                <nav className='nav' style={{ position: 'absolute', top: 0, right: '50%', zIndex: 99999 }}>
                    <Link to="/admin" style={{ textDecoration: 'none' }}>⚙️</Link>
                </nav>
            )}
            <Routes>
                <Route path="/admin" element={passwordAccepted ? <FullScreenSlider /> : <PasswordPrompt onPasswordAccepted={handlePasswordAccepted} />} />
                <Route path="/" element={<FullScreenForm />} />
            </Routes>
        </>
    );
};

const FullScreenSlider = () => {
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <Slider />
        </div>
    );
};

const FullScreenForm = () => {
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <MainForm />
        </div>
    );
};

export default AppWrapper;