import React, { useState, useEffect, useRef } from 'react';
import EntropyAnimation from './entropy';
import InitTrance from './trance';
import { FullLogoIcon } from '../icons';
import WaveSpeed from './speed';
import Timer from './timer';

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showArrows, setShowArrows] = useState(true);
    const timeoutRef = useRef(null);
    const sliderRef = useRef(null);

    const slides = [
        <div key={1} style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'gray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <EntropyAnimation />
        </div>,
        <div key={2} style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'gray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <WaveSpeed />
        </div>,
        <div key={3} style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'gray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <InitTrance />
        </div>,
            <div key={3} style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'gray',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
        }}>
            <Timer />
        </div>,
    ];

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
        resetTimer();
    };

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
        resetTimer();
    };

    const resetTimer = () => {
        clearTimeout(timeoutRef.current);
        setShowArrows(true);
        timeoutRef.current = setTimeout(() => {
            setShowArrows(false);
        }, 1000);
    };

    const handleMouseMove = () => {
        setShowArrows(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setShowArrows(false);
        }, 1000);
    };

    useEffect(() => {
        resetTimer();
        const sliderElement = sliderRef.current;
        sliderElement.addEventListener('mousemove', handleMouseMove);
        return () => {
            clearTimeout(timeoutRef.current);
            sliderElement.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div style={{ position: 'relative' }} ref={sliderRef}>
            {slides[currentSlide]}
            <div style={{
                position: 'absolute',
                top: 30,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 9999, // Set the z-index to a high value
            }}>
                {/* <LogoIcon size={100} /> */}
                <FullLogoIcon />
            </div>
            {showArrows && (
                <div>
                    <button onClick={handlePrevSlide} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                        &lt;
                    </button>
                    <button onClick={handleNextSlide} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                        &gt;
                    </button>
                </div>
            )}
        </div>
    );
};

export default Slider;