import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #222;
  width: 100vw;
`;

const WaveContainer = styled.div`
  position: relative;
  width: 50vw;
  height: 200px;
  overflow: hidden;
`;

const waveAnimation = (speed) => keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const Wave = styled.div`
  position: absolute;
  width: 200%;
  height: 50%;
  background-color: #03E23C;
  animation: ${({ speed }) => waveAnimation(speed)} ${({ speed }) => `${0.5 / speed}s`} linear infinite;
  border-radius: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;

const Knob = styled.input`
  appearance: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  outline: none;
  margin: 0 10px;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #03E23C;
    cursor: pointer;
  }
`;

const WaveSpeed = () => {
    const [isPlaying, setIsPlaying] = useState(true);
    const [speed, setSpeed] = useState(0.05);
    const waveRef = useRef(null);
    const requestRef = useRef(null);
  
    useEffect(() => {
      const animate = (time) => {
        if (waveRef.current) {
          const waveElement = waveRef.current;
          const waveWidth = waveElement.offsetWidth;
          const offsetX = (time / 1000) * speed * waveWidth;
          waveElement.style.transform = `translateX(-${offsetX % waveWidth}px)`;
        }
  
        if (isPlaying) {
          requestRef.current = requestAnimationFrame(animate);
        } else {
          cancelAnimationFrame(requestRef.current);
          requestRef.current = null; // Reset requestRef.current to null
        }
      };
  
      if (isPlaying) {
        requestRef.current = requestAnimationFrame(animate);
      }
  
      return () => cancelAnimationFrame(requestRef.current);
    }, [isPlaying, speed]);
  
    const toggleAnimation = () => {
      setIsPlaying((prevState) => !prevState);
    };
  
    const handleSpeedChange = (event) => {
      setSpeed(event.target.value);
    };
  
    return (
      <Container>
        <WaveContainer>
          <Wave speed={speed} ref={waveRef} />
        </WaveContainer>
        <ButtonContainer>
          {/* <Button onClick={toggleAnimation}>{isPlaying ? 'Pause' : 'Play'}</Button> */}
          <Knob type="range" min="0.05" max="10" step="0.1" value={speed} onChange={handleSpeedChange} />
        </ButtonContainer>
      </Container>
    );
  };
  
  export default WaveSpeed;