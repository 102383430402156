// store/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import FormDataSliceReducer from './formdata'
import ShowSubSliceReducer from './showsub'

const rootReducer = combineReducers({
    formdata: FormDataSliceReducer,
    showsub: ShowSubSliceReducer
});

// const persistConfig = {
//   key: 'root',
//   storage: AsyncStorage,
//   whitelist: ['calendardata'], // Specify the reducers you want to persist
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
// });


const store = configureStore({
    reducer: rootReducer,
});

export default store;
