import React, { useEffect, useRef, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { setShowSub } from './redux/showsub';
import '../App.css'

const MovingSineWaveAnimation = () => {

    // const dispatch = useDispatch()

    const canvasRef = useRef(null);


    const [transitionProgress, setTransitionProgress] = useState(0); // Declare transition value using useState

    const [isPlaying, setIsPlaying] = useState(true); // State to track if animation is playing

    // const [showsub, setShowSub] = useState(false)

    // useEffect(() => {
    //     if (transitionProgress >= 1) {
    //         setShowSub(true)
    //     }
    // }, [transitionProgress, dispatch])

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        let animationFrameId;
        let startTime = performance.now();
        // let transitionProgress = 0;
        let pausedTime = 0; // Track time when animation is paused



        const draw = (time) => {
            const deltaTime = isPlaying ? time - startTime - pausedTime : 0; // Update deltaTime only if animation is playing
            startTime = time;
            setTransitionProgress((prevProgress) => prevProgress + deltaTime / 10000); // Update transition progress


            // Only update canvas size if it has changed
            if (canvas.width !== canvas.clientWidth || canvas.height !== canvas.clientHeight) {
                canvas.width = canvas.clientWidth;
                canvas.height = canvas.clientHeight;
            }

            // Draw background gradient (only if canvas size has changed)
            if (deltaTime > 0) {
                const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
                // gradient.addColorStop(0, '#03E23C');
                // gradient.addColorStop(1, '#2878F6');
                gradient.addColorStop(1, '#fff');
                ctx.fillStyle = gradient;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
            }

            // // Add text to the canvas when chaotic points have disappeared
            // // if (transitionProgress >= 1) {
            // const alpha = Math.min(1, transitionProgress - 0.5); // Adjust this line to control the fade-in speed
            // ctx.fillStyle = `rgba(220,220,220, ${alpha})`; // Change this line
            // ctx.font = 'bold 40px Arial';
            // ctx.fillText('Clarity Beats Compromise', 130, 150);
            // // }

            // Draw chaotic points
            const pointCount = isPlaying ? 500 * (1 - transitionProgress) : 0; // Adjust the number of points
            const pointRadius = 5; // Adjust the size of the points
            ctx.fillStyle = 'rgba(9,9,11, 0.7)';

            for (let i = 0; i < pointCount; i++) {
                const x = Math.random() * canvas.width;
                const y = Math.random() * canvas.height;
                ctx.beginPath();
                ctx.arc(x, y, pointRadius, 0, 2 * Math.PI);
                ctx.fill();
            }

            // Draw sine wave
            const amplitude = isPlaying ? canvas.height / 40 * transitionProgress : 0; // Adjust amplitude of the wave
            const frequency = 0.05; // Adjust frequency of the wave
            const phaseShift = (time / 1000) * 2 * Math.PI; // Adjust speed of the animation
            const lineWidth = 2; // Adjust line width
            ctx.lineWidth = lineWidth;
            const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
            gradient.addColorStop(0, '#03E23C');
            gradient.addColorStop(1, '#2878F6');
            // gradient.addColorStop(1, '#000');
            ctx.strokeStyle = gradient

            ctx.beginPath();
            ctx.moveTo(0, canvas.height / 2);
            for (let x = 0; x < canvas.width; x++) {
                const y = amplitude * Math.sin(frequency * x + phaseShift) + canvas.height / 2;
                ctx.lineTo(x, y);
            }
            ctx.stroke();
            // Set the font style
            ctx.font = 'bold 25px Arial';
            const textAlpha = Math.min(1, transitionProgress * 0.5); // Adjust the multiplication factor to control the fade-in speed
            ctx.fillStyle = `rgba(128,128,128, ${textAlpha})`; // Gray color with alpha channel

            // Get the width of the text
            const textWidth = ctx.measureText('Clarity Beats Compromise!').width;

            // Calculate the x-coordinate to center the text
            const x = (canvas.width - textWidth) / 2;

            // Calculate the y-coordinate to center the text vertically
            const y = canvas.height / 2;

            // Add text to the canvas
            if (transitionProgress >= 1) {
                ctx.fillText('Clarity Beats Compromise!', x, y);
            }


            animationFrameId = requestAnimationFrame(draw);
        };

        draw(startTime);

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [transitionProgress, isPlaying]);

    const handleTogglePlay = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying); // Toggle play/pause state
    };

    return (
        <>
            <div className='animbtn'>
                {isPlaying ? (
                    <span onClick={handleTogglePlay} style={{ color: '#ccc' }}>&#10074;&#10074;</span>
                ) : (
                    <span onClick={handleTogglePlay} style={{ color: '#ccc' }}>&#9654;</span>
                )}
            </div>
            <canvas
                ref={canvasRef}
                style={{
                    width: '100%',
                    height: '100%',
                    // display: 'flex',
                    // marginTop: 100
                }}
            />

        </>

    );
};

export default MovingSineWaveAnimation;
