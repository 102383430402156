import '../App.css';
import { Provider, useSelector } from 'react-redux';
import store from '../component/redux/store';
import MovingSineWaveAnimation from '../component/anim';
import { FullLogoIcon } from '../component/icons';
import { useEffect, useState } from 'react';
// import PulsingCircle from '../component/animation/pulse';
import FormComponent from './formcomponent';
import whatsappLogo from '../assets/whatsapplogo.jpeg'; // Path to your WhatsApp logo image

const MainForm = () => {
    const [isMobile, setIsMobile] = useState(false);

    const showsub = useSelector(state => state.showsub)



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
        };

        handleResize(); // Call the function initially

        window.addEventListener('resize', handleResize); // Add event listener for window resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
    }, []);

    // const handleButtonClick = () => {
    //     window.open("https://innaffin.win", "_self");
    // };

    function WhatsAppLink() {
        const phoneNumber = "+918494912318"; // Replace with your phone number
        const message = encodeURIComponent("Hello, I would like more information about your the session.");

        return (
            <a href={`https://wa.me/${phoneNumber}?text=${message}`} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
                Chat with us on WhatsApp!
                <img src={whatsappLogo} alt="WhatsApp" style={{ marginRight: 8, width: 50 }} />
            </a>
        );
    }

    function WhatsAppButton() {
        const phoneNumber = "+918494912318";
        const message = encodeURIComponent("Hello, I would like more information about the session.");
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

        const handleWhatsAppRedirect = () => {
            window.open(whatsappURL, '_blank');
        };

        return (
            <button onClick={handleWhatsAppRedirect} style={{ color: 'green' }}>
                Chat with us on WhatsApp!
            </button>
        );
    }

    return (
        <div className="container">
            <Provider store={store}>
                <div className="left">
                    <div>
                        {isMobile
                            ? <div>
                                <FullLogoIcon size={100} />
                                <div style={{ padding: 20, backgroundColor: '#f0f0f0' }}>
                                <p style={{lineHeight: 2}}>
                                        Please complete the registration form to enroll in the session. If you have any questions, feel free to contact us before registering.
                                        <br /><br />
                                        The session will take place on the available dates from 6:00 AM to 9:00 AM.
                                        <br /><br />
                                        Please note that the session is offered at an introductory price, which will increase after the first 100 seats are filled.
                                    </p>
                                    {/* <WhatsAppLink /> */}
                                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                        <WhatsAppButton />
                                        <p style={{marginLeft: 10}}>or send an email to support@innaffin.win</p>
                                    </div>
                                </div>
                                <MovingSineWaveAnimation />
                            </div>
                            : <div>
                                <FullLogoIcon size={100} />
                                <div style={{ padding: 20, backgroundColor: '#f0f0f0' }}>
                                <p style={{lineHeight: 1.2}}>
                                        Please complete the registration form to enroll in the session. If you have any questions, feel free to contact us before registering.
                                        <br /><br />
                                        The session will take place on the available dates from 6:00 AM to 9:00 AM.
                                        <br /><br />
                                        Please note that the session is offered at an introductory price, which will increase after the first 100 seats are filled.
                                    </p>
                                    {/* <WhatsAppLink /> */}
                                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                        <WhatsAppButton />
                                        <p>or send an email to support@innaffin.win</p>
                                    </div>
                                </div>
                                <div style={{ position: 'absolute', bottom: 0, width: '50%' }}>
                                    <MovingSineWaveAnimation />
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="right">
                    {isMobile
                        ? <div style={{ marginTop: 550 }}>
                            <FormComponent />
                        </div>
                        : <div style={{ flex: 1 }}>
                            <FormComponent />
                        </div>}
                </div>
                {/* {isMobile ? <p>Mobile Device</p> : <p>Desktop Device</p>} */}

            </Provider>
        </div>
    );
};

export default MainForm;
