import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, collection, addDoc, updateDoc, increment, onSnapshot, serverTimestamp, query, orderBy } from 'firebase/firestore';
import { firebaseConfig } from './firebaseconfig';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from './redux/formdata';
import Modal from './modal';
import PulsingCircle from './animation/pulse';
import axios from 'axios';
// import DownloadPdf from './downloadPDF';
// import PDFGenerator from './downloadPDF';
import { load } from '@cashfreepayments/cashfree-js';
import uuid from 'react-native-uuid';
import { getAnalytics } from "firebase/analytics";



// Styled components for the form
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 280px; // Ensuring it fills the container, adjust according to your layout needs
  height: 100px; // Set a default height, you can control this via props if needed
  resize: vertical; // Allowing the user to resize the textarea vertically
  font-family: inherit; // Ensures the font is consistent with the rest of your form inputs
`;

const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
`;

const FormComponent = () => {

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  useEffect(() => {
    getAnalytics(app);
  }, [app])

  const dispatch = useDispatch()

  const formDataRedux = useSelector(state => state.formdata)

  const [formState, setFormState] = useState({
    userEmail: '',
    userName: '',
    userPaymentAmount: 10000,
    userPhone: '',
    userAddress: '',
    userSessionDate: '',
    userBatch: '',
    paymentOption: 'cashfree'
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [hideForm, setHideForm] = useState(false)

  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, 'dateCounts'), orderBy('timestamp')), (snapshot) => {
      const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in yyyy-mm-dd format
      const dates = snapshot.docs
        .filter(doc => {
          const docDate = doc.id;
          return doc.data().count < 10 && docDate >= currentDate; // Filter out dates with count < 10 and from today onwards
        })
        .map(doc => doc.id);
      setDates(dates);
      setLoading(false);
    });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();
  }, [db]);

  useEffect(() => {
    if (formState.userSessionDate) {
      setFormState(prevState => ({
        ...prevState,
        userBatch: 'TIS-' + prevState.userSessionDate
      }));
    }
  }, [formState.userSessionDate]); // Dependency on userSessionDate

  // MARK:Cashfree init
  // let cashFree;

  // let loadCashFreeScript = async () => {
  //   console.log("🚀 ~ loadCashFreeScript ~ loadCashFreeScript:", loadCashFreeScript)

  //   cashFree = await load({
  //     mode: "production" //or production
  //   });
  // }

  // loadCashFreeScript()


  useEffect(() => {

    const loadRazorpayScript = () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.id = 'razorpay-script'; // Add an id to the script element for cleanup
      document.body.appendChild(script);
    };
    loadRazorpayScript();
    // Cleanup function
    return () => {
      const script = document.getElementById('razorpay-script');
      if (script) {
        document.body.removeChild(script);
      }
    };


  }, []);


  useEffect(() => {
    console.log('formDataRedux', formDataRedux);
  }, [formDataRedux])

  const handleChange = (e) => {
    const newValue = e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: newValue
    });
  };

  console.log("🚀 ~ handleChange ~ formState:", formState)




  const closeModal = () => {
    setModalOpen(false);
    dispatch(setFormData({
      paymentStatus: null,
    }));
    setSubmitted(false)
    setHideForm(false)
  };

  useEffect(() => {
    // if (formDataRedux.paymentStatus.toLowerCase() === 'success' || formDataRedux.paymentStatus.toLowerCase() === 'failure') {
    if (['success', 'SUCCESS', 'failure'].includes(formDataRedux.paymentStatus)) {
      // setSubmitted(false);
      setModalOpen(true)

      // setShowTempMessage(true)
      // setTimeout(() => {
      //   setModalOpen(true)
      //   // setShowTempMessage(false)
      // }, 1000);
    }
  }, [formDataRedux])

  const selectRazorpay = async () => {
    try {
      const response = await fetch('/createOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formState: formState
        }), // Empty body, adjust if necessary
      });

      if (!response.ok) {
        throw new Error('Failed to create order');
      }


      const order = await response.json();
      console.log('Order created:', order);
      dispatch(setFormData({
        receiptNumber: order.receipt
      }));



      const options = {
        key: 'rzp_test_BXdMElFmubghKU',
        amount: formState.userPaymentAmount, // Amount in paise
        currency: 'INR',
        name: 'Innaffin',
        description: 'The Innaffin System',
        image: 'https://firebasestorage.googleapis.com/v0/b/innaffin.appspot.com/o/innaffin%20logo%2Finnaffin%20-%20logo.png?alt=media&token=33017ba7-74ca-4e81-aa79-3fda66e3c1be',
        order_id: order.id, // Use the order ID received from the server
        prefill: {
          name: formState.userName,
          email: formState.userEmail,
          contact: formState.userPhone
        },
        notes: {
          address: formState.userAddress
        },
        theme: {
          color: '#00E92F'
        },
        modal: {
          "ondismiss": function () {
            dispatch(setFormData({
              paymentStatus: 'failure',
            }));
          }
        },
        handler: async function (response) {
          dispatch(setFormData({
            paymentID: response.razorpay_payment_id,
            orderID: response.razorpay_order_id,
            paymentSign: response.razorpay_signature,
          }));

          // Send payment details to the backend for verification
          const verifyResponse = await fetch('/verifyPayment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature
            }),
          });

          if (!verifyResponse.ok) {
            throw new Error('Failed to verify signature');
          }

          const verificationResult = await verifyResponse.json();
          console.log('Verification result:', verificationResult.status);
          dispatch(setFormData({
            paymentStatus: verificationResult.status,
          }));
          console.log('Updated state:', formState);
        }
      };



      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error initiating payment:', error);
      // alert('Failed to initiate payment');
      dispatch(setFormData({
        paymentStatus: 'failure',
      }));
    }
  }

  const selectCashfree = async () => {
    console.log("🚀 ~ selectCashfree ~ selectCashfree:")

    try {
      // Using axios to make a POST request
      const response = await axios.post('/createOrder', {
        formState: formState
      });

      // Axios automatically checks the OK status and will throw an error if the status is outside of 200-299
      const order = response.data; // With axios, the JSON is already parsed.
      console.log("🚀 ~ selectCashfree ~ order:", order);

      console.log('Order created:', order.payment_session_id);
      // dispatch(setFormData({
      //   receiptNumber: order.receipt
      // }));

      let checkoutOptions = {
        paymentSessionId: order.payment_session_id,
        redirectTarget: "_modal"
      };
      const cashFree = await load({
        mode: "production" //or production
      });
      setSubmitted(false)

      cashFree.checkout(checkoutOptions).then(async (result) => {
        if (result.error) {
          // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
          console.log("User has closed the popup, Check for Payment Status");
          console.log(result.error);
          dispatch(setFormData({
            paymentStatus: 'failure',
          }));
        }
        if (result.redirect) {
          // This will be true when the payment redirection page couldnt be opened in the same window
          // This is an exceptional case only when the page is opened inside an inAppBrowser
          // In this case the customer will be redirected to return url once payment is completed
          console.log("Payment will be redirected");
        }
        if (result.paymentDetails) {
          // This will be called whenever the payment is completed irrespective of transaction status
          console.log("Payment has been completed, Check for Payment Status", result);
          console.log(result.paymentDetails.paymentMessage);
          // Send payment details to the backend for verification
          const verifyResponse = await fetch('/verifyPayment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              formState: formState,
              order: order
            }),
          });

          if (!verifyResponse.ok) {
            throw new Error('Failed to verify signature');
          }

          const verificationResult = await verifyResponse.json();
          console.log('Verification result:', verificationResult[0], verificationResult[0].payment_status);
          dispatch(setFormData({
            paymentStatus: verificationResult[0].payment_status,
            paymentID: verificationResult[0].cf_payment_id,
            orderID: verificationResult[0].order_id,
            paymentSign: 'cashfree',
          }));
        }
      });

    } catch (error) {
      console.log('Error initiating payment:', error);
      // Handling Axios specific errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      // alert('Failed to initiate payment');
      dispatch(setFormData({
        paymentStatus: 'failure',
      }));
    }
  }
  const handlePayment = async () => {
    if (formState.paymentOption === 'razorpay') {
      await selectRazorpay()
    } else if (formState.paymentOption === 'cashfree') {
      await selectCashfree()
    }
  };

  useEffect(() => {
    const handleUpload = async () => {
      try {
        const uploadFormData = {
          ...formState,
          ...formDataRedux, // Spread the existing formDataRedux
          userRegisteredOn: serverTimestamp(), // Add timestamp field
          userID: uuid.v4()
        };
        await addDoc(collection(db, 'forms'), uploadFormData)
          .then(async () => {
            // Get the document with the count of the selected date
            const dateDoc = doc(db, 'dateCounts', formState.userSessionDate);
            await updateDoc(dateDoc, { count: increment(1) })
          })
          .then(() => {

            // Reset the form and show the submission popup
            // setFormState({
            //   email: '',
            //   name: '',
            //   pricing: '',
            //   phoneNumber: '',
            //   address: '',
            //   date: '',
            // });
            // dispatch(setFormData(null))

          })
      } catch (error) {
        console.log("🚀 ~ handleUpload ~ error:", error)
      }
    }
    if (['success', 'SUCCESS'].includes(formDataRedux.paymentStatus)) {
      handleUpload()
    }
  }, [formDataRedux, formState])



  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitted(true);
      setHideForm(true)
      // Ensure formState.date is defined and is a non-empty string
      if (!formState.userSessionDate) {
        console.error('Date is not selected');
        return;
      }
      await handlePayment()
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)

    }
  };

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', /* Set the height to fill the entire viewport */
      }}>
        Loading ...
      </div>
    );
  }





  const formatDate = (dateString) => {

    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const renderForm = () => {
    if (submitted) {
      return (
        <div
          style={{
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            // background: 'transparent',
            padding: '20px',
            // border: '1px solid #ccc',
            borderRadius: '5px',
            // boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
            animation: 'slideIn 0.5s forwards',
            zIndex: -9999
          }}
        >
          <PulsingCircle />
          {/* <h2 style={{position: 'absolute', top: 300, right: 300}}>Text</h2> */}
        </div>
      );

    } else if (!hideForm) {
      return (
        <Form onSubmit={handleSubmit}>
          {/* <PDFGenerator />
          <p style={{ color: 'gray' }}>Register for the session</p> */}
          <h3 className='gradient-container-success' style={{ color: '#fff' }}>The Innaffin System</h3>
          <Input name="userEmail" required onChange={handleChange} placeholder="Email" />
          <Input name="userName" required onChange={handleChange} placeholder="Name" />
          {/* <Input name="pricing" required onChange={handleChange} placeholder="Pricing" value={formState.pricing} /> */}
          <Input name="userPhone" required onChange={handleChange} placeholder="Phone Number ( only 10 digits minus +91 or 0 )" maxLength={10} />
          <TextArea
            name="userAddress"
            required
            placeholder="Address"
            // value={userAddress}
            onChange={handleChange}
          />
          <p>* The entered details will be cross-checked with payment information. Please ensure accuracy.</p>
          <p>Registration Fee: ₹ {(formState.userPaymentAmount)}.00 only ( Ten thousand rupees only )</p>
          <p>* only for citizens of India.</p>

          <div>
            <p>Available Dates:</p>
            {dates
              // .filter(date => new Date(date) > new Date()) // Filter out dates in the past
              .map((date, index) => (
                <div key={index} style={{ marginBottom: 10 }}>
                  <Input
                    type="radio"
                    id={`date${index}`}
                    name="userSessionDate"
                    value={date}
                    onChange={handleChange}
                    checked={formState.userSessionDate === date}
                  />
                  <label htmlFor={`date${index}`} style={{ marginLeft: 10, fontWeight: 'bold' }}>
                    {formatDate(date)}
                  </label>
                </div>
              ))}
            {/* <button onClick={openModal}>Open Modal</button> */}
          </div>
          <Button type="submit" disabled={!formState.userSessionDate ? true : false} style={{ backgroundColor: !formState.userSessionDate ? 'gray' : null, marginBottom: 30, padding: 10, fontWeight: 'bold', fontSize: 30 }}>Register</Button>
        </Form>
      )
    }
  };

  return (
    <div>
      {renderForm()}
      <Modal isOpen={modalOpen} onClose={closeModal} formDataRedux={formDataRedux} formState={formState} />
    </div>



  );
};

export default FormComponent;
