import { Provider } from 'react-redux';
import store from './component/redux/store';
import { useEffect, useState } from 'react';
import AppWrapper from './AppWrapper';

const App = () => {

  // Define a constant at the top of your file
  const IS_PRODUCTION = true; // Set this to false when in development

  if (IS_PRODUCTION) {
    console.log = console.warn = console.error = function () { };
  }


  // const [isMobile, setIsMobile] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
console.log('status: ', status);
  },[status])

  // Send a ping request to the backend
  const sendPing = async () => {
    try {
      const response = await fetch('/ping', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          state: 'status'
        }), // Empty body, adjust if necessary
      });
      console.log("🚀 ~ sendPing ~ response:", response)
      if (response.status === 200) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } catch (error) {
      setStatus(false);
    }
  };

  // Call sendPing on page load
  useEffect(() => {
    sendPing();
  }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
  //   };

  //   handleResize(); // Call the function initially

  //   window.addEventListener('resize', handleResize); // Add event listener for window resize

  //   return () => {
  //     window.removeEventListener('resize', handleResize); // Cleanup on unmount
  //   };
  // }, []);

  return (
    <div className="container">
      <Provider store={store}>
        {/* <p>{status ? '\u2713' : '\u2718'}</p> */}
        <AppWrapper />
      </Provider>
    </div>
  );
};

export default App;
