import React from 'react';
import './pulse.css';

const PulsingCircle = ({ size }) => {
  return (
    <div className="pulsing-circle-container">
      <div className={size === 'small' ? "pulsing-circle-small" : "pulsing-circle"} />
    </div>
  );
};

export default PulsingCircle;
