// src/components/Modal.js
import React, { } from 'react';
import './modal.css'; // Create this CSS file for styling
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DownloadPdf from './downloadPDF';
import PDFGenerator from './downloadPDF';


const Modal = ({ isOpen, onClose, formState, formDataRedux }) => {
    const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
`;

    const paymentStatusInfo = () => {
        if (['success', 'SUCCESS'].includes(formDataRedux.paymentStatus)) {
            return <span style={{ color: '#00E92F', fontSize: 15 }}>Success &#10003;</span>
        } else {
            return <span style={{ color: 'red', fontSize: 15 }}>Failure &#10060;</span>
        }

    }

    const formatDate = (dateString) => {

        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };



    const renderContent = () => {
        if (['success', 'SUCCESS'].includes(formDataRedux.paymentStatus)) {
            return (
                <div className="modal-content">
                    <PDFGenerator formState={formState} formDataRedux={formDataRedux} />
                    <div className="gradient-container-success">
                        <h2>You are awesome! Welcome to the Innaffin club.</h2>
                    </div>

                    <h3>Receipt Summary</h3>
                    <ul>
                        <li>Name: {formState.userName}</li><br></br>
                        <li>Email: {formState.userEmail}</li><br></br>
                        <li>Phone Number: {formState.userPhone}</li><br></br>
                        <li>Address: {formState.userAddress}</li><br></br>
                        <li>Registration Fees: ₹ {(formState.userPaymentAmount)}.00</li><br></br>
                        <li>Session Date: {formatDate(formState.userSessionDate)}</li><br></br>
                        <li>Payment ID: {formDataRedux.paymentID}</li>

                    </ul>
                    <p style={{ fontWeight: 'bold' }}>Payment status: {paymentStatusInfo()}</p>
                    <p style={{ lineHeight: 2 }}>Thank you for your payment! The receipt will be automatically downloaded, please check your download folder.</p>

                    <h3>What next?</h3>
                    <p>The session link will be sent to your registered email address and the phone number a day in advance.</p>
                    <p>See you at the session.</p>

                    <h3>In the meantime,</h3>
                    <p style={{ lineHeight: 2 }}>Visit our website and familiarize yourself with the topics you'll be learning. Come prepared mentally.</p>
                    <a href="https://innaffin.win" target="_self" style={{ color: 'white', textDecoration: 'none' }}>
                        <h4 className='gradient-container-success'>
                            innaffin website
                        </h4>
                    </a>

                    {/* Add any other modal content here */}
                    {/* <button onClick={onClose}>Close</button> */}
                </div>
            )
        } else {
            return (
                <div className="modal-content">
                    <div className="gradient-container-failure">
                        <h2>Oops! Something went wrong.</h2>
                    </div>
                    <p style={{ fontWeight: 'bold' }}>Payment status: {paymentStatusInfo()}</p>
                    <Button type='button' onClick={onClose} style={{ marginBottom: 30, padding: 10, fontWeight: 'bold', fontSize: 30 }}>Retry</Button>



                    {/* Add any other modal content here */}
                    {/* <button onClick={onClose}>Close</button> */}
                </div>
            )
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            {renderContent()}
        </div >
    );
};

export default Modal;
