// store/dataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const FormDataSlice = createSlice({
    name: 'formdata',
    initialState: {},
    reducers: {
        setFormData: (state, action) => {
            // Merge the existing state with the new data
            return { ...state, ...action.payload };
        },
    },
});

export const { setFormData } = FormDataSlice.actions;
export default FormDataSlice.reducer;
