import React, { useEffect, useState } from 'react';
import { pdf, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo from '../assets/logofull.png'
// import axios from 'axios';
// import PulsingCircle from './animation/pulse';
import BurstAnimation from './animation/colorburst';

// Register the Google Font
Font.register({
    family: 'Roboto',
    src: 'https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf'
});


const PDFGenerator = ({ formState, formDataRedux }) => {
    const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handlePdfDownload()
    }, [])

    const formatDate = (dateString) => {

        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const getCurrentDateTimeIST = () => {
        const options = {
            year: 'numeric',  // Changed from '2-digit' to 'numeric' to show the full year
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,  // This ensures you use a 24-hour clock
            timeZone: 'Asia/Kolkata'
        };
        return new Intl.DateTimeFormat('en-IN', options).format(new Date());
    };


    const handlePdfDownload = async () => {
        setShowModal(true)
        setLoading(true)

        const blob = await pdf(
            <Document>
                <Page style={styles.body}>
                    <View style={styles.header}>
                        <Image src={logo} style={styles.logo} />
                        <Text style={styles.headerText}>Registration Payment Receipt</Text>
                        <View style={styles.paidStamp}>
                            <Text style={styles.paidStampText}>PAID</Text>
                        </View>
                    </View>



                    <View style={styles.courseInfo}>
                        <Text style={styles.courseTitle}>The Innaffin System</Text>
                        <Text style={styles.courseDescription}>
                            Learn the art of steering your life to the destination you decide, without a malfunctioning autopilot.
                        </Text>
                    </View>

                    <View style={styles.sessionInfo}>
                        <Text style={styles.sessionDate}>Registered on: {formatDate(formState.userSessionDate)}</Text>
                        <Text style={styles.courseRegistrationFee}>Registration Fee: {(formState.userPaymentAmount)}.00 Rupees only</Text>
                        <Text style={styles.sessionDate}>Session Date: {formatDate(formState.userSessionDate)}</Text>
                    </View>

                    <View style={styles.receiptSummary}>
                        <Text style={styles.summaryTitle}>Receipt Summary</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Name:</Text> {formState.userName}</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Email:</Text> {formState.userEmail}</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Phone:</Text> {formState.userPhone}</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Address:</Text> {formState.userAddress}</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Amount Paid:</Text> {(formState.userPaymentAmount)}.00 Rupees only</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Payment ID:</Text> {formDataRedux.paymentID}</Text>
                        <Text style={styles.summaryField}>
                            <Text style={styles.summaryLabel}>Payment Status:</Text> {(['success', 'SUCCESS', 'failure'].includes(formDataRedux.paymentStatus)) ? 'Paid' : 'Failed'}</Text>
                    </View>
                    <View style={styles.company}>
                        <Text style={styles.companyName}>Gravitystack® Consulting LLP</Text>
                        <Text style={styles.companyAddress}>LLPIN - AAP-1004</Text>
                        <Text style={styles.companyContact}>Contact: innaffin@gmail.com</Text>
                    </View>
                    <View style={styles.congratsMessage}>
                        <Text style={[styles.congratsText, styles.congratsTitle]}>
                            Congratulations on your enrollment!
                        </Text>
                        <Text style={styles.congratsText}>
                            We look forward to having you in the session. Get ready for an exciting journey ahead!
                        </Text>

                        <View style={styles.prerequisitesContainer}>
                            <Text style={[styles.congratsText, styles.prerequisitesTitle]}>Prerequisites:</Text>
                            <View style={styles.prerequisitesList}>
                                <Text style={styles.congratsText}>
                                    1. Attend with a prepared mind. Don't attend with assumptions and abstract expectations.
                                </Text>
                                <Text style={styles.congratsText}>
                                    2. Ability to converse or comprehend in English language is a must.
                                </Text>
                                <Text style={styles.congratsText}>3. Attend from a distraction-free zone / area.</Text>
                                <Text style={styles.congratsText}>4. Be prepared with headphones / earphones.</Text>
                                <Text style={styles.congratsText}>5. Require a laptop / desktop.</Text>
                                <Text style={styles.congratsText}>6. Must connect with a reliable internet connection.</Text>
                                <Text style={styles.congratsText}>6. A pen and a notebook.</Text>
                            </View>
                        </View>

                        <Text style={styles.congratsText}>
                            * No refunds will be provided.
                        </Text>
                    </View>
                    <Text style={styles.generatedText}>Receipt Generated on: {getCurrentDateTimeIST()}</Text>
                </Page>
            </Document>
        ).toBlob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'The Innaffin System - Registration payment receipt.pdf';
        link.click();

        setIsPdfDownloaded(true);
    };

    const sendEmail = async () => {
        try {
            const response = await fetch('/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    formState: formState,
                    formDataRedux: formDataRedux
                }), // Empty body, adjust if necessary
            });

            const responseData = await response.json(); // Parse JSON response
            if (!responseData.success) {
                throw new Error(`Failed to send email: ${responseData.error || 'Unknown error'}`);
            }
            if (responseData.success) {
                setIsEmailSent(true)
            }
            console.log("🚀 ~ sendEmail ~ response:", responseData);




        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    useEffect(() => {
        if (isPdfDownloaded) {
            sendEmail()
        }
    }, [isPdfDownloaded])

    useEffect(() => {
        if (isPdfDownloaded && isEmailSent) {
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }

    }, [isPdfDownloaded, isEmailSent])

    const closeModal = () => {
        setShowModal(false);
    };

    const styles = StyleSheet.create({
        body: {
            paddingVertical: 35,
            paddingHorizontal: 35,
            fontFamily: 'Roboto',

        },
        header: {
            marginBottom: 20,
        },
        headerText: {
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        company: {
            marginBottom: 20,
        },
        companyName: {
            fontSize: 10,
            fontWeight: 'bold',
        },
        companyAddress: {
            fontSize: 10,
        },
        companyContact: {
            fontSize: 10,
        },
        courseInfo: {
            marginBottom: 20,
        },
        courseTitle: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 10,
        },
        courseDescription: {
            fontSize: 12,
            marginBottom: 10,
        },
        courseRegistrationFee: {
            fontSize: 12,
            fontWeight: 'bold',
            marginVertical: 10
        },
        sessionInfo: {
            marginBottom: 20,

        },
        sessionDate: {
            fontSize: 12,
        },
        paymentId: {
            fontSize: 12,
        },
        paymentStatus: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        receiptSummary: {
            marginBottom: 20,
            borderWidth: 1,
            borderColor: '#000',
            borderStyle: 'solid',
            padding: 10,
        },
        summaryTitle: {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 10,
        },
        summaryField: {
            fontSize: 12,
            marginBottom: 5,
        },
        summaryLabel: {
            fontWeight: 'bold',
        },
        congratsMessage: {
            backgroundColor: '#f2f2f2',
            padding: 10,
            border: '1px solid #ccc',
            borderRadius: 5,
        },
        congratsTitle: {
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 10,
        },
        congratsText: {
            fontSize: 12,
            lineHeight: 1.5,
            textAlign: 'justify',
        },
        prerequisitesContainer: {
            marginVertical: 10,
        },
        prerequisitesTitle: {
            fontSize: 14,
            fontWeight: 'bold',
            marginBottom: 5,
        },
        prerequisitesList: {
            paddingLeft: 20,
        },
        logo: {
            width: '100%', // Adjust the width as per your requirement
            // height: 80, // Adjust the height as per your requirement
            marginBottom: 10, // Add some spacing below the logo
        },

        paidStamp: {
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: '#00E92F',
            padding: 5,
            borderRadius: 5,
        },
        paidStampText: {
            color: 'white',
            fontWeight: 'bold',
        },
        generatedText: {
            marginTop: 10,
            fontSize: 10,
            textAlign: 'center',
        }
    });

    return (
        <div>
            {/* {isPdfDownloaded && sendEmail()} */}
            {/* <button onClick={handlePdfDownload}>Download PDF</button> */}
            {/* {isPdfDownloaded && isEmailSent && <p>The receipt has been emailed to the registered address and downloaded to the computer successfully.!</p>} */}
            {showModal && (
                <div className="modal" style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000
                }} onClick={closeModal}>
                    <div className="modal-content" style={{
                        width: '50%', padding: '20px', borderRadius: '5px', backgroundColor: 'white',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', position: 'relative'
                    }} onClick={e => e.stopPropagation()}>
                        <div>
                            {loading ? (
                                <div>
                                    {/* <PulsingCircle size={'small'} /> */}
                                    <BurstAnimation />
                                </div>
                            ) : (
                                <div>
                                    <span style={{
                                        position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', fontSize: '24px'
                                    }} onClick={closeModal}>&times;</span>
                                    <p>The receipt has been emailed to the registered address and downloaded successfully. Please check your download folder.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
};

export default PDFGenerator;