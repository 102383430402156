import React, { useState, useRef } from 'react';
import soft_brush from '../../audio/soft_brush.wav'
import shadow_talks from '../../audio/shadow_talks.wav'
import frame_echo from '../../audio/frame_echo.wav'

const InitTrance = () => {
    const tracks = [
        { id: 1, src: soft_brush, label: 'Soft Brush' },
        { id: 2, src: shadow_talks, label: 'Shadow Talks' },
        { id: 3, src: frame_echo, label: 'Frame Echo' }
    ];
    const [isOn, setIsOn] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(null);
    const audioRefs = useRef(new Array(tracks.length));

    const handleToggle = () => {
        if (isOn) {
            if (currentTrack !== null) {
                const currentPlayingIndex = tracks.findIndex(track => track.id === currentTrack);
                const currentPlayingAudio = audioRefs.current[currentPlayingIndex];
                currentPlayingAudio.pause();
                currentPlayingAudio.currentTime = 0;
                setCurrentTrack(null);
            }
            setIsOn(false);
        } else {
            setIsOn(true);
        }
    };

    const playAudio = (trackId) => {
        const trackIndex = tracks.findIndex(track => track.id === trackId);
        const audio = audioRefs.current[trackIndex];

        if (currentTrack && currentTrack !== trackId) {
            // Stop the currently playing track
            const currentPlayingIndex = tracks.findIndex(track => track.id === currentTrack);
            const currentPlayingAudio = audioRefs.current[currentPlayingIndex];
            currentPlayingAudio.pause();
            currentPlayingAudio.currentTime = 0;
        }

        setCurrentTrack(trackId);

        if (audio.paused) {
            audio.play();
            setIsOn(true);
        } else {
            audio.pause();
            setIsOn(false);
        }
    };

    const handleAudioEnded = (trackId) => {
        if (currentTrack === trackId) {
            setIsOn(false);
        }
    };

    const containerStyle = {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isOn ? 'black' : 'white',
        transition: 'background-color 0.3s ease',
    };

    const bulbStyle = {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        backgroundColor: isOn ? 'yellow' : 'gray',
        boxShadow: isOn ? '0 0 40px 20px rgba(255, 255, 0, 0.5)' : 'none',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease',
        filter: isOn ? 'drop-shadow(0 0 10px rgba(255, 255, 0, 0.5))' : 'none',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '50vw' }}>
                <h2 style={{ color: isOn ? 'gray' : '#000', fontSize: 40 }}>{isOn ? 'State of trance initiated' : 'Initiate a state of trance'}</h2>
                <div style={bulbStyle} onClick={handleToggle}>
                </div>
            </div>
            <div style={{ display: 'flex', width: '50vw', justifyContent: 'space-evenly', marginTop: 50 }}>
                {tracks.map((track, index) => (
                    <button style={{ width: '20vh', height: '10vh', fontSize: '1rem', backgroundColor: isOn ? '#111':'#f0f0f0', color: '#000', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                        key={track.id} onClick={() => playAudio(track.id)}
                    >
                        {track.label}
                    </button>
                ))}
                {tracks.map((track, index) => (
                    <audio
                        key={track.id}
                        ref={el => audioRefs.current[index] = el}
                        src={track.src}
                        onEnded={() => handleAudioEnded(track.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default InitTrance;
