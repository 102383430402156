import React, { useState } from 'react';

const PasswordPrompt = ({ onPasswordAccepted }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'Lookahead@86') {
      onPasswordAccepted();
    } else {
      alert('Incorrect password. Please try again.');
      console.log('password === process.env.ADMIN_PASSWORD', password, process.env.ADMIN_PASSWORD);
    }
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
        <h2>Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter password"
            style={{ marginBottom: '10px' }}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPrompt;