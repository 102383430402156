import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';

const containerStyle = {
    // margin: '300px 1000px 0',  // Added top margin, and corrected unit for horizontal margins
    // maxWidth: '1200px',  // Adjust this value based on your layout needs
    padding: '20px',
    // marginTop: '250px',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'gray'
};

const rowStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
};

const columnStyle = {
    // flex: '1',
    width: '50vh',
    height: '40vh',
    margin: '10px',
    background: '#f0f0f0',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
};

const fullRowStyle = {
    ...columnStyle,
    // margin: '10px',
    width: '150vh',
    height: '15vh',
    // flex: 'none'  // reset the flex property to take the full width
};


function Timer() {
    const [time, setTime] = useState({
        seconds: 1,
        milliseconds: 0,
        microseconds: 0,
        nanoseconds: 0,
        picoseconds: 0,
        femtoseconds: 0  // Adding femtoseconds state
    });
    const [showMilliseconds, setShowMilliseconds] = useState(false);
    const [showMicroseconds, setShowMicroseconds] = useState(false);
    const [showNanoseconds, setShowNanoseconds] = useState(false);
    const [showPicoseconds, setShowPicoseconds] = useState(false);
    const [showFemtoseconds, setShowFemtoseconds] = useState(false);  // State to control femtosecond display
    const [zoom, setZoom] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => {
                let nextMilliseconds = prevTime.milliseconds + 10;
                let nextMicroseconds = Math.floor(Math.random() * 1000000);
                let nextNanoseconds = Math.floor(Math.random() * 1000000000);
                let nextPicoseconds = Math.floor(Math.random() * 1000000000000); // Random picoseconds
                let nextFemtoseconds = Math.floor(Math.random() * 1000000000000000);  // Random femtoseconds
                let nextSeconds = prevTime.seconds;

                if (nextMilliseconds >= 1000) {
                    nextMilliseconds = 0;
                    nextSeconds++; // Increment seconds as milliseconds wrap around
                }

                return {
                    seconds: nextSeconds,
                    milliseconds: nextMilliseconds,
                    microseconds: nextMicroseconds,
                    nanoseconds: nextNanoseconds,
                    picoseconds: nextPicoseconds,
                    femtoseconds: nextFemtoseconds
                };
            });
        }, 10); // Update the time every 10 milliseconds

        return () => clearInterval(interval);
    }, []);

    const handleReset = () => {
        setTime({ seconds: 1, milliseconds: 0, microseconds: 0, nanoseconds: 0, picoseconds: 0, femtoseconds: 0 });
    };

    const handleZoom = () => {
        setZoom(!zoom)
        setShowMilliseconds(false)
        setShowMicroseconds(false)
        setShowNanoseconds(false)
        setShowPicoseconds(false)
        setShowFemtoseconds(false)

    };

    const toggleMilliseconds = () => {
        setShowMilliseconds(prev => !prev);
    };

    const toggleMicroseconds = () => {
        setShowMicroseconds(prev => !prev);
    };

    const toggleNanoseconds = () => {
        setShowNanoseconds(prev => !prev);
    };

    const togglePicoseconds = () => {
        setShowPicoseconds(prev => !prev);
    };

    const toggleFemtoseconds = () => {
        setShowFemtoseconds(prev => !prev);
    };

    return (
        <div style={containerStyle}>
            <div style={{ marginTop: zoom ? 150 : null }}>
                <div style={rowStyle}>
                    {zoom ? <div style={{ ...columnStyle, backgroundColor: zoom ? '#f0f0f0' : null }}>
                        <h1 style={{ fontSize: 100 }}>{time.seconds}</h1>
                        <p style={{ fontWeight: 'bold' }}>Seconds</p>
                    </div>
                        : <div>
                            <h1 style={{ fontSize: 300, width: 100, color: '#ccc' }}>{time.seconds}</h1>
                        </div>}
                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                        <button style={{ marginBottom: 20, alignSelf: 'center', width: '20vh', height: '10vh', fontSize: '1rem', backgroundColor: '#f0f0f0', color: '#000', border: 'none', borderRadius: '4px', cursor: 'pointer' }} onClick={handleReset}>
                            Anchor
                        </button>
                        <button style={{ alignSelf: 'center', width: '20vh', height: '10vh', fontSize: '1rem', backgroundColor: '#f0f0f0', color: '#000', border: 'none', borderRadius: '4px', cursor: 'pointer' }} onClick={handleZoom}>
                            Zoom
                        </button>
                    </div>
                    {zoom && <div style={columnStyle}>
                        <h2>Zoomed time</h2>
                        {showMilliseconds && <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <p>.{time.milliseconds.toString().padStart(4, '0')}</p>
                            <p>Milliseconds</p>
                        </div>}
                        {showMicroseconds && <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <p>.{time.microseconds.toString().padStart(7, '0')}</p>
                            <p>Microseconds</p>
                        </div>}
                        {showNanoseconds && <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <p>.{time.nanoseconds.toString().padStart(10, '0')}</p>
                            <p>Nanoseconds</p>
                        </div>}
                        {showPicoseconds && <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <p>.{time.picoseconds.toString().padStart(13, '0')}</p>
                            <p>Picoseconds</p>
                        </div>}
                        {showFemtoseconds && <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <p>.{time.femtoseconds.toString().padStart(16, '0')}</p>
                            <p>Femtoseconds</p>
                        </div>}
                    </div>}
                </div>
                <div style={rowStyle}>
                    {zoom && <div style={fullRowStyle}>
                        <h2>Time zoom controls</h2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <label style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" checked={showMilliseconds} onChange={toggleMilliseconds} style={{ marginRight: '0.5rem' }} />
                                Milliseconds<br />( one thousandth )
                            </label>
                            <label style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" checked={showMicroseconds} onChange={toggleMicroseconds} style={{ marginRight: '0.5rem' }} />
                                Microseconds<br />( one millionth )
                            </label>
                            <label style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" checked={showNanoseconds} onChange={toggleNanoseconds} style={{ marginRight: '0.5rem' }} />
                                Nanoseconds<br />( one billionth )
                            </label>
                            <label style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" checked={showPicoseconds} onChange={togglePicoseconds} style={{ marginRight: '0.5rem' }} />
                                Picoseconds<br />( one trillionth )
                            </label>
                            <label style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" checked={showFemtoseconds} onChange={toggleFemtoseconds} style={{ marginRight: '0.5rem' }} />
                                Femtoseconds<br />( one quadrillionth )
                            </label>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Timer;
