import React, { useState, useEffect } from 'react';
import './colorburst.css'; // Import CSS file for styling

const BurstAnimation = ({ duration = 1000 }) => {
  const [bursting, setBursting] = useState(false);

  useEffect(() => {
    if (!bursting) {
      const timeout = setTimeout(() => {
        setBursting(true);
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [bursting, duration]);

  return (
    <div className={`burst-animation ${bursting ? 'bursting' : ''}`}>
      <div className="burst-circle" />
    </div>
  );
};

export default BurstAnimation;
